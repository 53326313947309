/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getProvinces, getCities, updateProfile } from '../../helper/function';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';

const ProfileVendor = ({ isEditProfile, dataProfile, getUserData }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [citiesWarehouseOptions, setCitiesWarehouseOptions] = useState([]);
  const [provinceWarehouseOptions, setProvinceWarehouseOptions] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedWarehouseProvince, setSelectedWarehouseProvince] =
    useState(null);
  const [selectedWarehouseCity, setSelectedWarehouseCity] = useState(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is Required'),
    email: Yup.string()
      .email('Please enter valid email address')
      .required('Email is required'),
    general_message: Yup.string().required('General Message is required'),
    about_merchant: Yup.string().required('Bio is required'),
    domain: Yup.string().url('Website URL format is not valid.'),
    instagram: Yup.string().url('Instagram URL is not valid.'),
    address: Yup.string().required('Address is Required'),
    address_2: Yup.string(),
    region_id: Yup.number().required('Province is Required'),
    district_id: Yup.number().required('City is Required'),
    postal_code: Yup.string()
      .required('Postcode is Required')
      .matches(/^[0-9\b]+$/, 'Number only'),
    phone: Yup.string().required('Phone number is Required'),
    wh_address: Yup.string().required('Address is Required'),
    wh_address_2: Yup.string(),
    wh_region_id: Yup.number().required('Province is Required'),
    wh_district_id: Yup.number().required('City is Required'),
    wh_postal_code: Yup.string()
      .required('Postcode is Required')
      .matches(/^[0-9\b]+$/, 'Number only'),
    wh_phone: Yup.string().required('Phone number is Required'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm(formOptions);

  const customStyles = {
    city: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
    province: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
  };

  const getProvinceData = async () => {
    const response = await getProvinces();
    if (response.message === 'Success' && response.data.length > 0) {
      let province = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setProvinceOptions(province);
      setProvinceWarehouseOptions(province);
    } else {
      setProvinceOptions([]);
    }
  };

  const getCitiesData = async (id = 1, type = 'office', firstLoad = false) => {
    const response = await getCities(id);
    if (response.message === 'Success' && response.data.length > 0) {
      let cities = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      if (type === 'office') setCitiesOptions(cities);
      if (type === 'warehouse') setCitiesWarehouseOptions(cities);
      if (selectedProvince && type === 'office' && !firstLoad)
        handleChangeCity(cities[0]);
      if (selectedWarehouseProvince && type === 'warehouse' && !firstLoad)
        handleChangeWarehouseCity(cities[0]);
    } else {
      if (type === 'office') setCitiesOptions([]);
      if (type === 'warehouse') setCitiesWarehouseOptions([]);
    }
  };

  const handleChangeProvince = (e) => {
    setSelectedProvince(e);
    setValue('region_id', e.value);
    getCitiesData(e.value);
  };

  const handleChangeCity = (e) => {
    setSelectedCity(e);
    setValue('district_id', e.value);
  };

  const handleChangeWarehouseProvince = (e) => {
    setSelectedWarehouseProvince(e);
    setValue('wh_region_id', e.value);
    getCitiesData(e.value, 'warehouse');
  };

  const handleChangeWarehouseCity = (e) => {
    setSelectedWarehouseCity(e);
    setValue('wh_district_id', e.value);
  };

  const onSubmitProfile = (data) => {
    setErrorMessage(null);
    setIsSubmitting(true);

    const payload = {
      ...data,
      use_custom_project: true,
      experience: dataProfile?.experience,
      availableStatus: dataProfile?.availableStatus,
      styleProject: dataProfile?.styleProject,
      cp_description: dataProfile?.cp_description,
      cp_price_per_m: dataProfile?.cp_price_per_m,
    };

    const loadingToast = toast.loading('Updating profile...');

    updateProfile(payload)
      .then((response) => {
        if (response.message === 'Success') {
          getUserData();
          toast.update(loadingToast, {
            render: 'Profile updated',
            type: 'success',
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });

          navigate('/');
          return;
        } else {
          setErrorMessage(response.message);
          toast.update(loadingToast, {
            render: response.message,
            type: 'error',
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (dataProfile) {
      setValue('name', dataProfile?.name);
      setValue('general_message', dataProfile?.general_message);
      setValue('email', dataProfile?.email);
      setValue('about_merchant', dataProfile?.about_merchant);
      setValue('domain', dataProfile?.domain ?? '');
      setValue('instagram', dataProfile?.instagram);
      setValue('address', dataProfile?.address);
      setValue('address_2', dataProfile?.address_2 ?? '');
      setValue('postal_code', dataProfile?.postal_code);
      setValue('phone', dataProfile?.phone);
      setValue('region_id', dataProfile?.region?.id);
      setValue('district_id', dataProfile?.district?.id);
      setValue('wh_address', dataProfile?.wh_address);
      setValue('wh_address_2', dataProfile?.wh_address_2 ?? '');
      setValue('wh_postal_code', dataProfile?.wh_postal_code);
      setValue('wh_phone', dataProfile?.wh_phone);
      setValue('wh_region_id', dataProfile?.wh_region?.id);
      setValue('wh_district_id', dataProfile?.wh_district?.id);

      const regionId = {
        value: dataProfile?.region?.id,
        label: dataProfile?.region?.name,
      };

      const districtId = {
        value: dataProfile?.district?.id,
        label: dataProfile?.district?.name,
      };

      const whRegionId = {
        value: dataProfile?.wh_region?.id,
        label: dataProfile?.wh_region?.name,
      };

      const whDistrictId = {
        value: dataProfile?.wh_district?.id,
        label: dataProfile?.wh_district?.name,
      };

      setSelectedProvince(regionId);
      setSelectedCity(districtId);
      setSelectedWarehouseProvince(whRegionId);
      setSelectedWarehouseCity(whDistrictId);
    }
  }, [dataProfile]);

  useEffect(() => {
    getProvinceData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitProfile)}>
      <div className="fs-5 fw-bold mb-4">
        Partner Information
        <i className="fa fas-pencil"></i>
      </div>
      {errorMessage !== null && (
        <div className="alert alert-danger alert-dismissible fade show">
          {errorMessage}
          <button
            type="button"
            className="close"
            onClick={() => setErrorMessage(null)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Username<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('name')}
          />
          {errors.name ? (
            <span className="text-danger">{errors.name.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Email<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('email')}
          />
          {errors.email ? (
            <span className="text-danger">{errors.email.message}</span>
          ) : null}
        </div>
      </div>
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            General Message<div className="text-danger ps-1">*</div>
          </label>
          <textarea
            disabled={!isEditProfile}
            className="form-control"
            rows="3"
            {...register('general_message')}
          ></textarea>
          {errors.general_message ? (
            <span className="text-danger">
              {errors.general_message.message}
            </span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Bio<div className="text-danger ps-1">*</div>
          </label>
          <textarea
            disabled={!isEditProfile}
            className="form-control"
            rows="3"
            {...register('about_merchant')}
          ></textarea>
          {errors.about_merchant ? (
            <span className="text-danger">{errors.about_merchant.message}</span>
          ) : null}
        </div>
      </div>
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">Website (URL)</label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('domain')}
          />
          {errors.domain ? (
            <span className="text-danger">{errors.domain.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Instagram Profile (URL)
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('instagram')}
          />
          {errors.instagram ? (
            <span className="text-danger">{errors.instagram.message}</span>
          ) : null}
        </div>
      </div>
      <div className="fs-5 fw-bold mt-5 mb-4">Office Location</div>
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Address<div className="text-danger ps-1">*</div>
          </label>
          <textarea
            disabled={!isEditProfile}
            className="form-control"
            rows="3"
            {...register('address')}
          ></textarea>
          {errors.address ? (
            <span className="text-danger">{errors.address.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Address Street 2
          </label>
          <textarea
            disabled={!isEditProfile}
            className="form-control"
            rows="3"
            {...register('address_2')}
          ></textarea>
          {errors.address_2 ? (
            <span className="text-danger">{errors.address_2.message}</span>
          ) : null}
        </div>
      </div>
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Province<div className="text-danger ps-1">*</div>
          </label>
          <Controller
            name="region_id"
            control={control}
            render={({ field }) => (
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selectedProvince}
                placeholder="Select Province"
                options={provinceOptions}
                isSearchable={true}
                styles={customStyles.province}
                menuPortalTarget={document.body}
                onChange={handleChangeProvince}
                isDisabled={!isEditProfile}
              />
            )}
          />
          {errors.region_id ? (
            <span className="text-danger">{errors.region_id.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            City<div className="text-danger ps-1">*</div>
          </label>
          <Controller
            name="district_id"
            control={control}
            render={({ field }) => (
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selectedCity}
                placeholder="Select City"
                options={citiesOptions}
                isSearchable={true}
                styles={customStyles.city}
                menuPortalTarget={document.body}
                onChange={handleChangeCity}
                isDisabled={!isEditProfile}
              />
            )}
          />
          {errors.district_id ? (
            <span className="text-danger">{errors.district_id.message}</span>
          ) : null}
        </div>
      </div>
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Postcode<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('postal_code')}
            maxLength={5}
          />
          {errors.postal_code ? (
            <span className="text-danger">{errors.postal_code.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Phone Number<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            defaultValue={dataProfile?.phone}
            {...register('phone')}
            maxLength={15}
          />
          {errors.phone ? (
            <span className="text-danger">{errors.phone.message}</span>
          ) : null}
        </div>
      </div>
      <div className="fs-5 fw-bold mt-5 mb-4">Warehouse Location</div>
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Address<div className="text-danger ps-1">*</div>
          </label>
          <textarea
            disabled={!isEditProfile}
            className="form-control"
            rows="3"
            {...register('wh_address')}
          ></textarea>
          {errors.wh_address ? (
            <span className="text-danger">{errors.wh_address.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Address Street 2
          </label>
          <textarea
            disabled={!isEditProfile}
            className="form-control"
            rows="3"
            {...register('wh_address_2')}
          ></textarea>
          {errors.wh_address_2 ? (
            <span className="text-danger">{errors.wh_address_2.message}</span>
          ) : null}
        </div>
      </div>
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Province<div className="text-danger ps-1">*</div>
          </label>
          <Controller
            name="wh_region_id"
            control={control}
            render={({ field }) => (
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selectedWarehouseProvince}
                placeholder="Select Province"
                options={provinceWarehouseOptions}
                isSearchable={true}
                styles={customStyles.province}
                menuPortalTarget={document.body}
                onChange={handleChangeWarehouseProvince}
                isDisabled={!isEditProfile}
              />
            )}
          />
          {errors.wh_region_id ? (
            <span className="text-danger">{errors.wh_region_id.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            City<div className="text-danger ps-1">*</div>
          </label>
          <Controller
            name="wh_district_id"
            control={control}
            render={({ field }) => (
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selectedWarehouseCity}
                placeholder="Select City"
                options={citiesWarehouseOptions}
                isSearchable={true}
                styles={customStyles.city}
                menuPortalTarget={document.body}
                onChange={handleChangeWarehouseCity}
                isDisabled={!isEditProfile}
              />
            )}
          />
          {errors.wh_district_id ? (
            <span className="text-danger">{errors.wh_district_id.message}</span>
          ) : null}
        </div>
      </div>
      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Postcode<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('wh_postal_code')}
            maxLength={5}
          />
          {errors.wh_postal_code ? (
            <span className="text-danger">{errors.wh_postal_code.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Phone Number<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('wh_phone')}
            maxLength={15}
          />
          {errors.wh_phone ? (
            <span className="text-danger">{errors.wh_phone.message}</span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end">
        {!isSubmitting && (
          <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
            <button
              type="submit"
              className="btn w-100 fw-semibold btn-fill px-5 mt-3 ask"
            >
              Save
            </button>
          </div>
        )}
        {isSubmitting && (
          <button
            type="submit"
            disabled
            className="btn w-sm-100 m-0 m-sm-1 btn-fill fw-semibold"
          >
            <div
              className="spinner-border"
              role="status"
              style={{ width: '1.5rem', height: '1.5rem' }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </button>
        )}
      </div>
    </form>
  );
};

export default ProfileVendor;
