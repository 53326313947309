import { useState } from 'react';
import ScrollTracker from '../ScrollTracker/ScrollTracker';
import PdfViewer from '../PdfViewer/PdfViewer';

const ModalAgreement = ({ handleReadAgreement }) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  function handleUpdateScrollPercentage(value) {
    setScrollPercentage(value);
  }

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 fw-bold" id="exampleModalLabel">
              Agreement
            </h1>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ScrollTracker handleIndicator={handleUpdateScrollPercentage}>
              <PdfViewer
                fileUrl={
                  'https://kisahruang.s3.ap-southeast-1.amazonaws.com/internal/Perjanjian_Kerjasama.pdf'
                }
              />
            </ScrollTracker>
          </div>
          <div className="modal-footer">
            <button
              disabled={scrollPercentage <= 98.99}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => handleReadAgreement(true)}
            >
              Understand
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAgreement;
