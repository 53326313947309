import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfile } from '../../../helper/function';
import Select from 'react-select';
import {
  addPortfolio,
  getDetailPortfolio,
  updatePortfolio,
  getListRoomStyle,
} from '../../../helper/function';
import {
  formatMoney,
  getBase64FromUrlV3,
  getFileExtension,
} from '../../../helper/lib';
import FileUpload from '../../../organism/FileUpload/FileUpload';

import $ from 'jquery';
import { IsAlert } from '../../../atoms/IsAlert';

const AddPortfolio = (props) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [budget, setBudget] = useState(
    props?.dataProfile?.cp_price_per_m
      ? formatMoney(props.dataProfile.cp_price_per_m, '')
      : 0
  );
  const [styles, setStyles] = useState([]);
  const [description, setDescription] = useState('');
  const [styleSelected, setStyleSelected] = useState([]);
  const [showUploadInput, setShowUploadInput] = useState(true);
  const [samplePreview, setSamplePreview] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [idData, setIdData] = useState(null);
  const [tempPhotos, setTempPhotos] = useState([]);
  const [styleDesignTemp, setStyleDesignTemp] = useState('[]');
  const [tsx, setTsx] = useState(Date.now());

  const location = useLocation();
  const id = location.pathname.slice(27);

  const getDetailPortfolioData = async () => {
    const response = await getDetailPortfolio(id);

    if (response.message === 'Success') {
      const {
        nameTitle,
        budget,
        descriptionDesign,
        styleDesignId,
        id,
        photos,
      } = response.data;
      setTitle(nameTitle);
      setBudget(formatMoney(budget, ''));
      setDescription(descriptionDesign);
      setIdData(id);
      setTempPhotos(photos);
      setStyleDesignTemp(styleDesignId);

      const previewImages = [];
      for (let i = 0; i < photos.length; i++) {
        let el = photos[i];
        if (el.full_image_url !== '') {
          let base64File = await getBase64FromUrlV3(el.full_image_url);
          if (base64File !== '' && base64File !== undefined) {
            previewImages.push({
              name: `response-photo-${i}.jpg`,
              size: '-',
              preview: el.full_image_url + '?tsx=' + tsx,
              file: base64File,
            });
          }
        }
      }

      setSamplePreview(previewImages);
    }
  };

  const getUserData = async () => {
    const res = await getProfile();

    if (res.meta.total > 0) {
      props.changeDataProfile(res.data);
    } else {
      console.error({ res });
    }
  };

  const getStyleLists = async () => {
    const response = await getListRoomStyle();
    if (response.message === 'Success') {
      let styleTags = [];
      response?.data.map((val, index) => {
        styleTags.push({
          value: val?.id,
          label: val?.name,
        });
        return;
      });
      setStyles(styleTags);

      // set style selected
      let dataSelected = [];
      JSON.parse(styleDesignTemp).map((style, index) => {
        let findStyle = styles.find(
          (item) => parseInt(item.value) === parseInt(style)
        );
        dataSelected.push(findStyle);
        return;
      });
      setStyleSelected(dataSelected);
    }
  };

  useEffect(() => {
    if (location.pathname.slice(20, 26) === 'update') {
      setIsEdit(true);
      getDetailPortfolioData();
    }
    getStyleLists();
    getUserData();
  }, []);

  useEffect(() => {
    // set style selected
    let dataSelected = [];
    JSON.parse(styleDesignTemp).map((style, index) => {
      let findStyle = styles.find(
        (item) => parseInt(item.value) === parseInt(style)
      );
      dataSelected.push(findStyle);
      return;
    });
    setStyleSelected(dataSelected);
  }, [styleDesignTemp, styles]);

  const handleChange = (selectedOption) => {
    setStyleSelected(selectedOption);
  };

  const handleUploadFiles = async (files) => {
    if (samplePreview.length + files.length > 5) {
      IsAlert.fire({
        title: 'Error',
        text: 'Maximum Upload 5 Files',
        icon: 'error',
      });

      return;
    }
    files.forEach(async (file) => {
      const fileExtension = await getFileExtension(file.name);
      const fsize = Math.round((file.size / 1000000) * 100) / 100;
      if (!['jpeg', 'jpg', 'png'].includes(fileExtension.toLowerCase())) {
        // Alert
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload file with the following extension only: jpeg, jpg, png',
          icon: 'error',
        });

        return;
      }

      if (fsize > 3.0) {
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload image with a size of 3 MB or less per file',
          icon: 'error',
        });
        return;
      }

      const previewImage = URL.createObjectURL(file);
      const base64 = await convertBase64(file);

      setSamplePreview((prev) => [
        ...prev,
        {
          name: file.name,
          size: fsize,
          preview: previewImage,
          file: base64,
        },
      ]);

      return;
    });
  };

  const convertBase64 = async (file, isBlob) => {
    let blob = file;
    if (isBlob) {
      blob = await file.blob();
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const clearImageSelected = (index) => {
    const filtered = samplePreview.filter((_, i) => {
      return i !== index;
    });
    setSamplePreview(filtered);
  };

  useEffect(() => {
    // show hide box image upload
    if (samplePreview.length >= 5) {
      setShowUploadInput(false);
    } else {
      if (showUploadInput) {
        return;
      }
      setShowUploadInput(true);
    }
  }, [samplePreview]);

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      const style = JSON.stringify(
        styleSelected.map((i) => {
          return i.value;
        })
      );

      let uplodPhotos = [];
      samplePreview.forEach((el) => {
        uplodPhotos.push(el.file);
      });

      const payload = {
        title: title,
        budget: parseInt(budget.replaceAll('.', '')),
        style: style,
        description: description,
        photos: uplodPhotos,
        docs: [],
      };
      if (isEdit) {
        payload.portfolioId = idData;
        payload.isPrimary = true;
        const response = await updatePortfolio(payload);
        if (response?.message === 'Success') navigate(-1);
        if (response?.message !== 'Success') {
          IsAlert.fire({
            title: 'Failed',
            text: response?.message ?? 'Something when wrong.',
            icon: 'error',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        }
        return;
      }
      if (!isEdit) {
        const response = await addPortfolio(payload);

        if (response?.message === 'Success') navigate('/designer/portfolio');
        if (response?.message !== 'Success') {
          IsAlert.fire({
            title: 'Failed',
            text: response?.message ?? 'Something when wrong.',
            icon: 'error',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        }
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container-fluid mb-4 px-0 tab-navigasi">
      <div className="mb-4">
        <a onClick={() => navigate(-1)} className="fs-6 fw-bold">
          <img
            src={'/dist/img/custom/caret-left-light.svg'}
            className="icon-4 me-2"
            alt="img-caret-left-light"
          />
          {isEdit && 'Edit Portfolio'}
          {!isEdit && 'Add Portfolio'}
        </a>
      </div>
      <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
        <div className="w-100 swiper-nav swiper">
          <ul
            className="nav nav-pills ff2 nav-wrap swiper-wrapper"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item swiper-slide" role="presentation">
              <button
                className="nav-link active"
                id="pills-information-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-information"
                type="button"
                role="tab"
                aria-controls="pills-information"
                aria-selected="true"
              >
                Information
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              {title && budget && styleSelected ? (
                <button
                  className="nav-link"
                  id="pills-photos-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-photos"
                  type="button"
                  role="tab"
                  aria-controls="pills-photos"
                  aria-selected="false"
                >
                  Photos
                </button>
              ) : (
                <button
                  disabled
                  className="nav-link"
                  id="pills-photos-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-photos"
                  type="button"
                  role="tab"
                  aria-controls="pills-photos"
                  aria-selected="false"
                >
                  Photos
                </button>
              )}
            </li>
          </ul>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>

      <div>
        <div className="tab-content" id="pills-tabContent">
          {/* <!-- Information --> */}
          <div
            className="tab-pane fade show active"
            id="pills-information"
            role="tabpanel"
            aria-labelledby="pills-information-tab"
          >
            <div className="fs-5 fw-bold mt-2 mb-4">Portfolio Information</div>
            <div className="d-sm-flex mb-4">
              <div className="col-12 col-sm-6 ps-sm-2">
                <label className="form-label d-flex fw-semibold">
                  Title<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                  required
                />
              </div>
              <div className="col-12 col-sm-6 ps-sm-2">
                <label className="form-label d-flex fw-semibold">
                  Style<div className="text-danger ps-1">*</div>
                </label>
                <Select
                  isMulti
                  options={styles}
                  value={styleSelected}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="d-sm-flex mb-4">
              {/* <div className="col-12 col-sm-6 ps-sm-2">
                <label className="form-label d-flex fw-semibold">
                  Budget<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={budget}
                  onChange={(event) => {
                    setBudget(event.target.value);
                  }}
                  onKeyUp={(event) =>
                    setBudget(
                      formatMoney(event.target.value.replaceAll('.', ''), '')
                    )
                  }
                />
              </div> */}
              <div className="col-12 col-sm-6 ps-sm-2">
                <label className="form-label d-flex fw-semibold">
                  Description
                </label>
                <textarea
                  className="form-control"
                  rows="3"
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
                <button
                  type="button"
                  disabled={!title || !budget || styleSelected.length === 0}
                  className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                  onClick={() => {
                    $('#pills-photos-tab').click();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          {/* <!-- Photos --> */}
          <div
            className="tab-pane fade"
            id="pills-photos"
            role="tabpanel"
            aria-labelledby="pills-photos-tab"
          >
            <div className="fs-5 fw-bold mt-2 mb-4">Photos</div>

            {showUploadInput ? (
              <FileUpload
                upload={handleUploadFiles}
                samplePreviews={samplePreview}
                maxFile={5}
                maxSizePerFile={3}
              />
            ) : null}

            <div className="d-flex justify-content-center">
              <div className="w-100 row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mt-3 me-0">
                {samplePreview && samplePreview.length > 0
                  ? samplePreview.map((image, index) => {
                      return (
                        <div key={index}>
                          <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                                <div className="w-100 h-100">
                                  <img
                                    id="imageId"
                                    src={image?.preview}
                                    className="object-fit-cover w-5rem ar-square"
                                    alt="Preview"
                                  />
                                </div>
                              </div>
                              <div className="col ms-3">
                                <p className="mb-0 text-wrap-1">
                                  {image?.name}
                                </p>
                                <div className="fz-14 text-secondary mt-3">
                                  {image?.size} MB
                                </div>
                              </div>
                            </div>
                            <button className="btn p-0">
                              <img
                                src="/dist/img/custom/x.svg"
                                className="icon-3"
                                alt="img-delete"
                                onClick={clearImageSelected.bind(this, index)}
                              />
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>

            <div className="d-flex justify-content-between mt-2">
              <div className="col-12 col-sm-4 ps-sm-2">
                <button
                  type="button"
                  className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                  onClick={() => {
                    $('#pills-information-tab').click();
                  }}
                >
                  Previous
                </button>
              </div>

              <div className="col-12 col-sm-4 ps-sm-2 text-end">
                <button
                  type="button"
                  className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                  disabled={!(samplePreview.length > 0)}
                  onClick={submitForm}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    dataProfile: state.dataProfile,
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
    changeDataProfile: (data) =>
      dispatch({ type: 'CHANGE_DATA_PROFILE', payload: data }),
  };
};

export default connect(mapState, actionCreators)(AddPortfolio);
