/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { getDetailPortfolio, deletePortfolio } from '../../../helper/function';
import { getProfile } from '../../../helper/function';
import { formatMoney } from '../../../helper/lib';
import { IsAlert } from '../../../atoms/IsAlert';

const DetailPortofolio = (props) => {
  const navigate = useNavigate();
  const [detailPortfolio, setDetailPortfolio] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const [tsx, setTsx] = useState(Date.now());
  const location = useLocation();
  const id = location.pathname.slice(25);

  const selectedImage = (image) => {
    setMainImage(image);
  };

  const deleteItem = async (id) => {
    if (detailPortfolio?.isPrimary) {
      IsAlert.fire({
        title: 'Error',
        text: `This portfolio is portfolio cover. It can't be deleted before portofolio cover is changed.`,
        icon: 'error',
      });
      return;
    }
    const response = await deletePortfolio(id);
    if (response.message === 'Success') navigate('/designer/portfolio');
  };

  const getUserData = async () => {
    const res = await getProfile();

    if (res.meta.total > 0) {
      props.changeDataProfile(res.data);
    } else {
      console.error({ res });
    }
  };

  const getDetailPortfolioData = async () => {
    const response = await getDetailPortfolio(id);
    if (response.message === 'Success') {
      setDetailPortfolio(response.data);
      const image = response?.data?.photos[0]?.full_image_url;
      setMainImage(image);
    } else {
      setDetailPortfolio([]);
    }
  };

  useEffect(() => {
    getDetailPortfolioData();
    getUserData();
  }, []);

  return (
    <div>
      <div className="container-fluid mb-4 px-0 tab-navigasi">
        <div className="mb-4 px-4 px-md-0">
          <a onClick={() => navigate(-1)} className="fs-6 fw-bold">
            <img
              src={'/dist/img/custom/caret-left-light.svg'}
              className="icon-4 me-2"
              alt="img-Table-Empty"
            />
            Portfolio Information
          </a>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="d-flex mt-2 mb-4"
            style={{ maxHeight: '640px', height: '100%' }}
          >
            <div className="col-12 col-md-6 pe-md-4">
              <div className="d-flex h-100">
                <div className="col-4 col-lg-2 h-100 pe-2">
                  <div className="carusel-nav">
                    {detailPortfolio?.photos?.map(function (image, index) {
                      return image?.full_image_url !== '' ? (
                        <div
                          className="my-2 cursor-pointer"
                          key={index}
                          onClick={selectedImage.bind(
                            this,
                            image?.full_image_url
                          )}
                        >
                          <img
                            src={
                              `${image?.full_image_url}?tsx=${tsx}` ||
                              'https://placehold.co/600x400'
                            }
                            alt="Imaj"
                            className="carusel-list-img"
                          />
                        </div>
                      ) : (
                        <div key={index}></div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-8 col-lg-10 h-100 ps-2">
                  <div className="carusel">
                    <div>
                      <img
                        src={`${mainImage}?tsx=${tsx}`}
                        alt="Imaj"
                        className="carusel-thumbnail"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 ps-md-4">
              <h2 className="fw-bold" style={{ fontSize: '50px' }}>
                {detailPortfolio?.nameTitle}
              </h2>
              <dl className="portfolio-desc mt-4">
                <dt>Price (Per Meter)</dt>
                <dd>
                  {/* {formatMoney(
                    detailPortfolio?.budget
                      ? detailPortfolio?.budget
                      : props?.dataProfile?.cp_price_per_m
                      ? props.dataProfile.cp_price_per_m
                      : 0
                  )} */}
                  {formatMoney(
                    props?.dataProfile?.cp_price_per_m
                      ? props.dataProfile.cp_price_per_m
                      : detailPortfolio?.budget
                      ? detailPortfolio?.budget
                      : 0
                  )}
                </dd>
                <dt className="mt-4">Style</dt>
                <dd>
                  {/* <span className="badge bg-white text-secondary px-0 py-2 me-2 fs-6">Classic</span>
                        <span className="badge bg-white text-secondary px-0 py-2 me-4 fs-6">Modern</span> */}
                  <span className="badge bg-white text-secondary px-0 py-2 me-4 fs-6">
                    {detailPortfolio.styleDesign && (
                      <>
                        {detailPortfolio.styleDesign
                          .replaceAll('[', '')
                          .replaceAll(']', '') === '' && '-'}

                        {detailPortfolio.styleDesign
                          .replaceAll('[', '')
                          .replaceAll(']', '')
                          .replaceAll(',', ', ')}
                      </>
                    )}
                  </span>
                </dd>
                <dt className="mt-4">Description</dt>
                <dd>{detailPortfolio?.descriptionDesign}</dd>
              </dl>
              <div className="d-flex justify-content-between mt-4">
                <NavLink
                  to={`/designer/portfolio/update/${detailPortfolio?.id}`}
                  className="btn flex-grow-1 fw-semibold btn-fill me-2 mt-3 ask"
                >
                  Edit
                </NavLink>
                <button
                  type="button"
                  className="btn flex-grow-1 fw-semibold btn-danger ms-2 mt-3 ask"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDeletePortfolio"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalDeletePortfolio"
        tabIndex="-1"
        aria-labelledby="modalDeletePortfolioLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="w-100 empty-wrap h-unset d-flex align-items-center justify-content-center">
                <div className="d-grid px-3 px-sm-0">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="img-wrap img-wrap-empty">
                      <img
                        src="../../../dist/img/custom/Table-Empty.svg"
                        alt="img-Table-Empty"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <div className="fs-4 fw-bold text-center">
                      Delete Portfolio
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-2 mb-4 text-center">
                    Are you sure want to delete this portfolio?
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 position-relative">
              <div className="w-50 m-0 p-1">
                <button
                  type="button"
                  className="btn w-100 btn-line fw-semibold"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="w-50 m-0 p-1">
                <button
                  type="button"
                  className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold"
                  data-bs-dismiss="modal"
                  onClick={deleteItem.bind(this, detailPortfolio?.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    dataProfile: state.dataProfile,
  };
};

export default connect(mapState)(DetailPortofolio);
