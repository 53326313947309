/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import {
  addDesignConceptProject,
  getDetailDesignConcept,
  updateDesignConceptProject,
  getDetailProject,
  getDetailProjectByOrderNo,
} from '../../../helper/function';
import { getFileExtension, getBase64FromUrlV3 } from '../../../helper/lib';
import $ from 'jquery';
import { IsAlert } from '../../../atoms/IsAlert';
import PdfThumbnail from '../../../organism/Project/PdfThumbnail';
import LoadingComponent from '../../../atoms/LoadingComponent';
import moment from 'moment';
import FileUpload from '../../../organism/FileUpload/FileUpload';

const AddProjectDesign = (props) => {
  moment.locale('en');
  const navigate = useNavigate();
  const location = useLocation();
  const { orderNo, projectID, id } = useParams();
  const [samplePreview, setSamplePreview] = useState([]);
  const [showUploadInput, setShowUploadInput] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [project, setProject] = useState(null);
  const [roomTypeExpress, setRoomTypeExpress] = useState([]);
  const [tsx, setTsx] = useState(Date.now());

  // Form state
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  //
  const getDetailDesign = async () => {
    setIsLoadingData(true);
    const payload = {
      idProject: projectID,
      orderNo: orderNo,
      id: id,
    };
    const response = await getDetailDesignConcept(payload);
    const previewImages = [];
    if (response.message === 'Success' && response.meta.total > 0) {
      setTitle(response.data.titleDesigner);
      setDescription(response.data.description);
      for (let i = 0; i < response.data.photos.length; i++) {
        const el = response.data.photos[i];
        if (el.full_image_url !== '') {
          let base64File = await getBase64FromUrlV3(el.full_image_url);
          if (base64File !== '' && base64File !== undefined) {
            previewImages.push({
              name: 'response-photo.jpg',
              size: '-',
              preview: el.full_image_url + '?tsx=' + tsx,
              file: base64File,
              extension: 'png',
            });
          }
        }
      }

      // docs / pdf
      for (let i = 0; i < response.data.docs.length; i++) {
        const el = response.data.docs[i];
        if (el.full_doc_url !== '') {
          let base64File = await getBase64FromUrlV3(el.full_doc_url);
          if (base64File !== '' && base64File !== undefined) {
            previewImages.push({
              name: 'response-docs.pdf',
              size: '-',
              preview: el.full_doc_url,
              file: base64File,
              extension: 'pdf',
            });
          }
        }
      }

      setIsLoadingData(false);
    }
    await setSamplePreview(previewImages);
  };

  const getRoomTypeExpress = async () => {
    const payload = {
      orderNo: orderNo,
    };
    const response = await getDetailProjectByOrderNo(payload);
    if (response.message === 'Success' && response.meta.total > 0) {
      let projectResp = response.data;
      setRoomTypeExpress(projectResp);
    }
  };

  const getProjectData = async () => {
    // Set loading
    setIsLoadingData(true);

    const payload = {
      orderNo: orderNo,
      id: projectID,
    };
    const response = await getDetailProject(payload);
    if (response.message === 'Success' && response.meta.total > 0) {
      let projectResp = response.data;
      setProject(projectResp);

      if (
        projectResp?.projectInformatin?.service === 'Express Package' ||
        project?.service === 'Express Package'
      ) {
        getRoomTypeExpress();
      }
      // Set loading
      setIsLoadingData(false);
    } else {
      setProject(null);
      // Set loading
      setIsLoadingData(false);

      // Alert
      IsAlert.fire({
        title: 'Error',
        text: response?.message ?? 'Something when wrong.',
        icon: 'error',
      });
    }
  };

  //
  useEffect(() => {
    if (id !== undefined) {
      setIsEdit(true);
      getDetailDesign();
    }
    getProjectData();
  }, []);

  useEffect(() => {
    // show hide box image upload
    if (samplePreview.length >= 5) {
      setShowUploadInput(false);
    } else {
      if (showUploadInput) {
        return;
      }
      setShowUploadInput(true);
    }
  }, [samplePreview]);

  const handleUpload = async (files) => {
    if (samplePreview.length + files.length > 5) {
      IsAlert.fire({
        title: 'Error',
        text: 'Maximum Upload 5 Files',
        icon: 'error',
      });

      return;
    }
    files.forEach(async (file) => {
      const fileExtension = await getFileExtension(file.name);
      const fsize = Math.round((file.size / 1000000) * 100) / 100;
      if (
        !['jpeg', 'jpg', 'png', 'pdf'].includes(fileExtension.toLowerCase())
      ) {
        // Alert
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload file with the following extension only: jpeg, jpg, png, pdf',
          icon: 'error',
        });

        return;
      }

      if (fsize > 3.0) {
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload image with a size of 3 MB or less per file',
          icon: 'error',
        });
        return;
      }

      const previewImage = URL.createObjectURL(file);
      const base64 = await convertBase64(file);

      setSamplePreview((prev) => [
        ...prev,
        {
          name: file.name,
          size: fsize,
          preview: previewImage,
          file: base64,
          extension: fileExtension,
        },
      ]);

      return;
    });
  };

  const convertBase64 = async (file, isBlob) => {
    let blob = file;
    if (isBlob) {
      blob = await file.blob();
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const clearImageSelected = (index) => {
    const filtered = samplePreview.filter((_, i) => {
      return i !== index;
    });
    setSamplePreview(filtered);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Disable button
    $('.btn-save').prop('disabled', true);

    let docs = [];
    let photos = [];
    samplePreview.forEach((el) => {
      el.extension === 'pdf' ? docs.push(el.file) : photos.push(el.file);
    });

    if (!isEdit) {
      const payload = {
        IdProject: projectID,
        orderNo: orderNo,
        title: title,
        description: description,
        designType: location?.state?.designType ?? 0,
        photos: photos,
        docs: docs,
      };
      const response = await addDesignConceptProject(payload);
      let redirectUrlSuccess = `/designer/project/view/${orderNo}/${projectID}`;
      if (response?.message === 'Success')
        navigate(redirectUrlSuccess, {
          state: {
            tab: 'designPlan',
            designType: location?.state?.designType,
          },
        });

      // Error
      if (response?.message !== 'Success') {
        $('.btn-save').prop('disabled', false);
        IsAlert.fire({
          title: 'Fail',
          text: response.message,
          icon: 'error',
        });
      }
    }

    if (isEdit) {
      const payload = {
        id: id,
        IdProject: projectID,
        orderNo: orderNo,
        title: title,
        description: description,
        photos: photos,
        docs: docs,
      };
      const response = await updateDesignConceptProject(payload);
      let redirectUrlSuccess = `/designer/project/detail-design/${orderNo}/${id}/${projectID}`;
      if (response?.message === 'Success')
        navigate(redirectUrlSuccess, {
          state: {
            designType: location?.state?.designType,
          },
        });

      // Error
      if (response?.message !== 'Success') {
        $('.btn-save').prop('disabled', false);
        IsAlert.fire({
          title: 'Fail',
          text: response.message,
          icon: 'error',
        });
      }
    }
  };

  return (
    <div className="container-fluid mb-4 px-0 tab-navigasi">
      <div className="mb-4">
        <Link
          to={{
            pathname: `/designer/project/view/${orderNo}/${projectID}`,
            state: {
              tab: 'designPlan',
              designType: location?.state?.designType,
            },
          }}
          className="fs-6 fw-bold"
        >
          <img
            src={'/dist/img/custom/caret-left-light.svg'}
            className="icon-4 me-2"
            alt="img-caret-left-light"
          />
          {isEdit && 'Edit Design Concept'}
          {!isEdit && 'New Design Concept'}
        </Link>
      </div>
      <hr className="mt-5 mb-5" />

      {/* Project Information */}
      <div className="fs-5 fw-bold mt-2 mb-4">Project Information</div>

      <table>
        <tbody>
          <tr className="align-top">
            <td width="150">
              <p className="fz-14 ff2">Project No</p>
            </td>
            <td>
              <p className="fz-14 fw-bold">
                {project?.projectInformatin?.orderNo ?? '-'}
              </p>
            </td>
          </tr>
          <tr className="align-top">
            <td width="150">
              <p className="fz-14 ff2">Service</p>
            </td>
            <td>
              <p className="fz-14 fw-bold">
                {project?.projectInformatin?.service ?? '-'}
              </p>
            </td>
          </tr>
          <tr className="align-top">
            <td width="150">
              <p className="fz-14 ff2">Status</p>
            </td>
            <td>
              <div className="badge-new mb-3 badge-gray">
                {project?.projectInformatin?.status ?? '-'}
              </div>
            </td>
          </tr>
          {project?.projectInformatin?.status === 'Cancelled' && (
            <>
              {roomTypeExpress?.map((room, index) => {
                return (
                  <tr className="align-top" key={index}>
                    <td width="150">
                      <p className="fz-14 ff2">Room Type {index + 1}</p>
                    </td>
                    <td>
                      <p className="fz-14 fw-bold">{room?.roomTypes ?? '-'}</p>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
          {project?.projectInformatin?.status !== 'Cancelled' && (
            <>
              <tr className="align-top">
                <td width="150">
                  <p className="fz-14 ff2">Room Type</p>
                </td>
                <td>
                  <p className="fz-14 fw-bold">
                    {project?.projectInformatin?.roomTypes ?? '-'}
                  </p>
                </td>
              </tr>
            </>
          )}
          <tr className="align-top">
            <td width="150">
              <p className="fz-14 ff2">Design Package</p>
            </td>
            <td>
              <p className="fz-14 fw-bold">
                {project?.projectInformatin?.designerPackage ?? '-'}
              </p>
            </td>
          </tr>
          <tr className="align-top">
            <td width="150">
              <p className="fz-14 ff2">Name</p>
            </td>
            <td>
              <p className="fz-14 fw-bold">
                {project?.projectInformatin?.name ?? '-'}
              </p>
            </td>
          </tr>
          <tr className="align-top">
            <td width="150">
              <p className="fz-14 ff2">Phone Number</p>
            </td>
            <td>
              <p className="fz-14 fw-bold">
                {project?.projectInformatin?.phoneNumber ?? '-'}
              </p>
            </td>
          </tr>
          <tr className="align-top">
            <td width="150">
              <p className="fz-14 ff2">Email</p>
            </td>
            <td>
              <p className="fz-14 fw-bold">
                {project?.projectInformatin?.email ?? '-'}
              </p>
            </td>
          </tr>
          <tr className="align-top">
            <td width="150">
              <p className="fz-14 ff2">Last Update</p>
            </td>
            <td>
              <p className="fz-14 fw-bold">
                {project?.projectInformatin?.updatedAt
                  ? moment(
                      project?.projectInformatin?.updatedAt.replaceAll(
                        ' WIB',
                        ''
                      ),
                      'ddd MMM DD HH:mm:ss YYYY',
                      true
                    ).format('ddd DD MMM YYYY HH:mm:ss ') + 'WIB'
                  : '-'}
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <hr className="mt-5 mb-5" />

      {/* Profile Information */}
      <div className="fs-5 fw-bold mt-2 mb-4">Profile Information</div>
      <form onSubmit={handleSubmit}>
        <div className="d-sm-flex mb-3">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label d-flex fw-semibold">
              Title<div className="text-danger ps-1">*</div>
            </label>
            <textarea
              className="form-control"
              rows="2"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            ></textarea>
          </div>
        </div>
        <div className="d-sm-flex mb-3">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
            <label className="form-label d-flex fw-semibold">
              Description<div className="text-danger ps-1">*</div>
            </label>
            <p>
              {location?.state?.designType === 1 &&
                'Describe your mood board & item selection here'}
              {location?.state?.designType === 2 &&
                'Describe your 3d design & item selection here'}
              {location?.state?.designType === 3 &&
                'Describe your working drawings & item selection here'}
              {location?.state?.designType === undefined &&
                'Describe your mood board & item selection here'}
            </p>
            <textarea
              className="form-control"
              rows="4"
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            ></textarea>
          </div>
        </div>

        {/* Photo */}
        <div className="fs-5 fw-bold mt-2 mb-4 mt-5">Photo</div>

        {showUploadInput ? (
          <FileUpload
            upload={handleUpload}
            samplePreviews={samplePreview}
            maxFile={5}
            maxSizePerFile={3}
            pdf={false}
          />
        ) : null}

        <div className="d-flex justify-content-center">
          {/* Loading */}
          {isLoadingData === true && <LoadingComponent />}

          {samplePreview.length > 0 && !isLoadingData && (
            <div className="w-100 row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mt-3 me-0">
              {samplePreview.map((image, index) => {
                return (
                  <div key={index}>
                    <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                          <div className="w-100 h-100">
                            {image.extension !== 'pdf' && (
                              <img
                                id="imageId"
                                src={image.preview}
                                className="object-fit-cover w-5rem ar-square"
                                alt="Preview"
                              />
                            )}

                            {image.extension === 'pdf' && (
                              <PdfThumbnail url={image.preview} />
                            )}
                          </div>
                        </div>
                        <div className="col ms-3">
                          <p className="mb-0 text-wrap-1">{image.name}</p>
                          <div className="fz-14 text-secondary mt-3">
                            {image.size} Mb
                          </div>
                        </div>
                      </div>
                      <button className="btn p-0" type="button">
                        <img
                          src={'/dist/img/custom/x.svg'}
                          className="icon-3"
                          alt="img-delete"
                          onClick={clearImageSelected.bind(this, index)}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="text-right">
          <button
            type="submit"
            className="btn fw-semibold btn-fill px-5 mt-3 ask btn-save"
            disabled={!(title && description && samplePreview.length > 0)}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(AddProjectDesign);
