/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getProfile,
  uploadBanner,
  uploadProfilePhoto,
  getBenefits,
} from '../../helper/function';
import { getBase64StringFromDataURL, addDefaultSrc } from '../../helper/lib';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { toast } from 'react-toastify';
import ProfileDesigner from './ProfileDesigner';
import ProfileVendor from './ProfileVendor';

const Profile = (props) => {
  const location = useLocation();
  const locationPathOne = location.pathname.split('/')[1];
  const isEditProfile = true;
  const [isEditBanner, setIsEditBanner] = useState(false);
  const [isEditProfilePhoto, setIsEditProfilePhoto] = useState(false);
  const [bannerCropper, setBannerCropper] = useState();
  const [bannerImage, setBannerImage] = useState('');
  const [profilePhotoCropper, setProfilePhotoCropper] = useState();
  const [profilePhotoImage, setProfilePhotoImage] = useState('');

  const [isUploadingBanner, setIsUploadingBanner] = useState(false);
  const [isUploadingProfilePhoto, setIsUploadingProfilePhoto] = useState(false);

  const [benefits, setBenefits] = useState([]);
  const bannerFileInput = useRef(null);
  const profilePhotoFileInput = useRef(null);

  const onAttachBanner = (e) => {
    e.preventDefault();

    if (e?.target?.files.length <= 0) return;

    let selectedFile = e?.target?.files[0];

    const MAX_FILE_SIZE = 2048; // 2MB
    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error('File size banner is more than 2MB');
      return;
    }

    setIsEditBanner(true);

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setBannerImage(reader.result);
    };

    reader.readAsDataURL(files[0]);
  };

  const onAttachProfilePhoto = (e) => {
    e.preventDefault();

    if (e?.target?.files.length <= 0) return;

    let selectedFile = e?.target?.files[0];

    const MAX_FILE_SIZE = 2048; // 2MB
    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error('File size profile is more than 2MB');
      return;
    }

    setIsEditProfilePhoto(true);

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setProfilePhotoImage(reader.result);
    };

    reader.readAsDataURL(files[0]);
  };

  const getBannerCropData = () => {
    return new Promise((dispatch) => {
      if (typeof bannerCropper !== 'undefined') {
        setBannerImage(bannerCropper.getCroppedCanvas().toDataURL());
        dispatch(bannerCropper.getCroppedCanvas().toDataURL());
      }
    });
  };

  const getProfilePhotoCropData = () => {
    return new Promise((dispatch) => {
      if (typeof profilePhotoCropper !== 'undefined') {
        setProfilePhotoImage(
          profilePhotoCropper.getCroppedCanvas().toDataURL()
        );
        dispatch(profilePhotoCropper.getCroppedCanvas().toDataURL());
      }
    });
  };

  const handleEditBanner = (e) => {
    e.preventDefault();
    bannerFileInput.current.click();
  };

  const handleEditProfilePhoto = (e) => {
    e.preventDefault();
    profilePhotoFileInput.current.click();
  };

  const handleSaveEditBanner = async (e) => {
    e.preventDefault();
    setIsEditBanner(false);
    let bannerFile = await getBannerCropData();
    if (bannerFile) await uploadBannerToApi(bannerFile);
  };

  const handleSaveEditProfilePhoto = async (e) => {
    e.preventDefault();
    setIsEditProfilePhoto(false);
    let profilePhotoFile = await getProfilePhotoCropData();

    if (profilePhotoFile) await uploadProfilePhotoToApi(profilePhotoFile);
  };

  const uploadBannerToApi = async (bannerFile) => {
    setIsUploadingBanner(true);

    let photo = await getBase64StringFromDataURL(bannerFile);

    const dataSend = {
      photo: photo,
    };

    const loadingToast = toast.loading('Uploading...');

    let response = await uploadBanner(dataSend);

    if (response.message === 'Success') {
      getUserData();
      setIsUploadingBanner(false);
      toast.update(loadingToast, {
        render: 'Banner image changed',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      setIsUploadingBanner(false);
      toast.update(loadingToast, {
        render: response.message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const uploadProfilePhotoToApi = async (profilePhotoFile) => {
    setIsUploadingProfilePhoto(true);

    const dataSend = {
      photo: getBase64StringFromDataURL(profilePhotoFile),
    };
    const loadingToast = toast.loading('Uploading...');

    let response = await uploadProfilePhoto(dataSend);

    if (response.message === 'Success') {
      getUserData();
      setIsUploadingProfilePhoto(false);
      toast.update(loadingToast, {
        render: 'Logo image changed',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      setIsUploadingProfilePhoto(false);
      toast.update(loadingToast, {
        render: response.message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const getUserData = async () => {
    const res = await getProfile();

    if (res.meta.total > 0) {
      props.changeDataProfile(res.data);
      props.setLoading(false);
    } else {
      console.error({ res });
    }
  };

  const fetchBenefits = async () => {
    const response = await getBenefits();
    if (response && response.status === 'success') {
      const newInputs = response?.data?.map((input) => ({
        ...input,
      }));
      setBenefits(newInputs);
      return;
    }

    toast.error(response?.message);
  };

  React.useEffect(() => {
    setBannerImage(
      props.dataProfile?.url_banner.length > 0
        ? props.dataProfile?.url_banner
        : '#'
    );
    setProfilePhotoImage(
      props.dataProfile?.logo.length > 0
        ? props.dataProfile?.logo
        : '/dist/img/logo.svg'
    );
  }, [props.dataProfile]);

  useEffect(() => {
    if (!props.dataProfile) {
      getUserData();
    }
  }, []);

  useEffect(() => {
    if (locationPathOne === 'designer') {
      fetchBenefits();
    }
  }, [locationPathOne]);

  return (
    <div className="tab-content" id="pills-tabContent">
      {/* <!-- Profile --> */}
      <div
        className="tab-pane fade show active"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <div>
          <div className="w-100 position-relative img-profile-wrapper">
            <div
              className="w-100 d-flex align-items-center justify-content-center rounded-lg overflow-hidden position-relative back-profile"
              style={{ border: '1px solid #e2e2e2' }}
            >
              {!isEditBanner && (
                <div className="img-wrap w-100 h-100 object-fit-cover">
                  <img
                    alt=""
                    src={bannerImage}
                    onError={addDefaultSrc}
                    className="object-fit-cover"
                  />
                </div>
              )}
              {isUploadingBanner && (
                <div
                  className="bg-white w-100 h-100"
                  style={{ position: 'absolute', filter: 'opacity(0.5)' }}
                ></div>
              )}
              {isEditBanner && (
                <Cropper
                  src={bannerImage}
                  style={{ height: '20rem', width: '100%' }}
                  // Cropper.js options
                  guides={true}
                  viewMode={3}
                  zoomOnTouch={false}
                  toggleDragModeOnDblclick={false}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  dragMode="move"
                  height={'20rem'}
                  width={'2000px'}
                  autoCropArea={1}
                  cropBoxData={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  onInitialized={(instance) => {
                    setBannerCropper(instance);
                  }}
                />
              )}
              <div className="btn-back-background">
                <input
                  accept="image/*"
                  type="file"
                  ref={bannerFileInput}
                  onChange={onAttachBanner}
                  className="d-none"
                />
                {!isEditBanner && !isUploadingBanner && (
                  <>
                    <button
                      className="btn btn-fill border d-flex justify-content-center align-items-center"
                      onClick={handleEditBanner}
                    >
                      <div className="img-wrap">
                        <img
                          alt=""
                          src="../dist/img/custom/camera.svg"
                          className="filter-white icon-2"
                        />
                      </div>
                      <div className="ms-2 d-none d-md-block">
                        Edit Cover Image
                      </div>
                    </button>
                  </>
                )}
                {(isEditBanner || isUploadingBanner) && (
                  <button
                    className={`btn btn-line-gray border d-flex justify-content-center align-items-center ${
                      isUploadingBanner ? 'disabled' : ''
                    }`}
                    onClick={handleSaveEditBanner}
                    disabled={isUploadingBanner}
                  >
                    {!isUploadingBanner && (
                      <div className="img-wrap">
                        <img
                          alt=""
                          src="../dist/img/custom/ic-done.svg"
                          className=" icon-2"
                        />
                      </div>
                    )}
                    <div className="ms-2 d-none d-md-block">
                      {isUploadingBanner ? 'Uploading..' : 'Save Cover Image'}
                    </div>
                  </button>
                )}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center justify-content-md-start ps-md-5 img-profile">
              <div className="av-circle overflow-unset position-relative">
                <div
                  className="av-circle border-white border"
                  style={
                    isUploadingProfilePhoto ? { filter: 'opacity(0.5)' } : {}
                  }
                >
                  {!isEditProfilePhoto && (
                    <img
                      alt=""
                      src={profilePhotoImage}
                      onError={addDefaultSrc}
                    />
                  )}
                  {isEditProfilePhoto && (
                    <Cropper
                      src={profilePhotoImage}
                      // style={{ height: "12rem", width: "12rem" }}
                      // Cropper.js options
                      guides={true}
                      viewMode={3}
                      zoomOnTouch={false}
                      toggleDragModeOnDblclick={false}
                      cropBoxMovable={false}
                      cropBoxResizable={false}
                      dragMode="move"
                      autoCropArea={1}
                      cropBoxData={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      onInitialized={(instance) => {
                        setProfilePhotoCropper(instance);
                      }}
                    />
                  )}
                </div>

                {locationPathOne === 'designer' && (
                  <div
                    className="position-absolute btn-profile-background"
                    style={{ right: '9rem' }}
                  >
                    <div
                      className="img-wrap"
                      style={{ height: '32px', width: '32px' }}
                    >
                      {props?.dataProfile?.group_name?.toLowerCase() ===
                        'platinum' && (
                        <img
                          src={`/dist/img/profile/group-platinum.png`}
                          alt="group-img"
                        />
                      )}
                      {props?.dataProfile?.group_name?.toLowerCase() ===
                        'elite' && (
                        <img
                          src={`/dist/img/profile/group-platinum.png`}
                          alt="group-img"
                        />
                      )}
                      {props?.dataProfile?.group_name?.toLowerCase() ===
                        'celebrity' && (
                        <img
                          src={`/dist/img/profile/group-platinum.png`}
                          alt="group-img"
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className="position-absolute btn-profile-background">
                  <input
                    accept="image/*"
                    type="file"
                    ref={profilePhotoFileInput}
                    onChange={onAttachProfilePhoto}
                    className="d-none"
                  />
                  {!isEditProfilePhoto && !isUploadingProfilePhoto && (
                    <button
                      className="btn btn-fill border rounded-circle icon-hw-b-1 p-0 d-flex align-items-center justify-content-center"
                      onClick={handleEditProfilePhoto}
                    >
                      <div className="img-wrap">
                        <img
                          alt=""
                          src="../dist/img/custom/camera.svg"
                          className="filter-white"
                        />
                      </div>
                    </button>
                  )}
                  {(isEditProfilePhoto || isUploadingProfilePhoto) && (
                    <button
                      className={`btn btn-line-gray border rounded-circle icon-hw-b-1 p-0 d-flex align-items-center justify-content-center ${
                        isUploadingProfilePhoto ? 'disabled' : ''
                      }`}
                      onClick={handleSaveEditProfilePhoto}
                      disabled={isUploadingProfilePhoto}
                    >
                      {!isUploadingProfilePhoto && (
                        <div className="img-wrap">
                          <img
                            alt=""
                            src="../dist/img/custom/ic-done.svg"
                            className=" icon-2"
                          />
                        </div>
                      )}
                      {isUploadingProfilePhoto && (
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ width: '1.5rem', height: '1.5rem' }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {locationPathOne === 'designer' && (
              <div
                className="w-100 d-flex align-items-center justify-content-end rounded-lg overflow-hidden position-relative mt-2"
                style={{ gap: '5px' }}
              >
                {props?.dataProfile?.group_name?.toLowerCase() ===
                  'platinum' && (
                  <img
                    src={`/dist/img/profile/group-platinum.png`}
                    alt="group-img"
                    style={{ height: '24px', width: '24px' }}
                  />
                )}
                {props?.dataProfile?.group_name?.toLowerCase() === 'elite' && (
                  <img
                    src={`/dist/img/profile/group-platinum.png`}
                    alt="group-img"
                    style={{ height: '24px', width: '24px' }}
                  />
                )}
                {props?.dataProfile?.group_name?.toLowerCase() ===
                  'celebrity' && (
                  <img
                    src={`/dist/img/profile/group-platinum.png`}
                    alt="group-img"
                    style={{ height: '24px', width: '24px' }}
                  />
                )}

                <span className="fs-5 fw-bold mb-0">
                  {props?.dataProfile?.group_name}
                </span>
              </div>
            )}
          </div>

          {locationPathOne === 'designer' && props?.dataProfile && (
            <ProfileDesigner
              dataProfile={props?.dataProfile}
              isEditProfile={isEditProfile}
              benefits={benefits}
              getUserData={getUserData}
            />
          )}

          {locationPathOne !== 'designer' && props?.dataProfile && (
            <ProfileVendor
              dataProfile={props?.dataProfile}
              isEditProfile={isEditProfile}
              getUserData={getUserData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    dataProfile: state.dataProfile,
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
    changeDataProfile: (data) =>
      dispatch({ type: 'CHANGE_DATA_PROFILE', payload: data }),
  };
};

export default connect(mapState, actionCreators)(Profile);
