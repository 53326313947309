/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ProjectDetailComponent from '../../../organism/Project/ProjectDetailComponent';
import { IsAlert } from '../../../atoms/IsAlert';
import LoadingComponent from '../../../atoms/LoadingComponent';
import {
  getDetailProject,
  updateProjectStatus,
} from '../../../helper/function';

const ProjectDetail = (props) => {
  const navigate = useNavigate();
  const { orderNo, id } = useParams();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [tsx, setTsx] = useState(Date.now());
  const [project, setProject] = useState(null);

  const getProjectData = async () => {
    // Set loading
    setIsLoadingData(true);

    const payload = {
      orderNo: orderNo,
      id: id,
    };
    const response = await getDetailProject(payload);
    if (response.message === 'Success' && response.meta.total > 0) {
      const projectResp = response.data;
      // projectResp.projectInformatin.service = 'Custom Project' // For testing
      setProject(projectResp);
      // Set loading
      setIsLoadingData(false);
    } else {
      setProject(null);
      // Set loading
      setIsLoadingData(false);

      // Alert
      IsAlert.fire({
        title: 'Error',
        text: response?.message ?? 'Something when wrong.',
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    getProjectData();
  }, []);

  // Accept Project
  const handleAcceptOrder = async () => {
    IsAlert.fire({
      title: 'Accept',
      text: 'Are you sure want to accept this project?',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Accept',
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        // Set loading
        setIsLoadingData(true);

        // Accept order
        const payload = {
          orderNo: orderNo,
          status: 1,
        };
        const response = await updateProjectStatus(payload);
        if (response?.message === 'Success') {
          navigate(`/designer/project/view/${orderNo}`);
        }

        // Error
        if (response?.message !== 'Success') {
          // Set loading
          setIsLoadingData(false);
          IsAlert.fire({
            title: 'Fail',
            text: response.message,
            icon: 'error',
          });
        }
      }
    });
  };

  // Reject Project
  const handleRejectOrder = async () => {
    IsAlert.fire({
      title: 'Reject',
      text: 'Are you sure want to reject this project?',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Reject',
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        // Set loading
        setIsLoadingData(true);

        // Reject order
        const payload = {
          orderNo: orderNo,
          status: 5,
        };
        const response = await updateProjectStatus(payload);
        if (response?.message === 'Success') {
          navigate(`/designer/project/view/${orderNo}`);
        }

        // Error
        if (response?.message !== 'Success') {
          // Set loading
          setIsLoadingData(false);
          IsAlert.fire({
            title: 'Fail',
            text: response.message,
            icon: 'error',
          });
        }
      }
    });
  };

  return (
    <div className="container-fluid mb-4 px-0 tab-navigasi">
      <div className="mb-4">
        <div className="mb-4">
          {((project?.projectInformatin?.status === 'New Project' &&
            project?.projectInformatin?.status !== undefined) ||
            (project?.projectInformatin?.status === 'Cancelled' &&
              project?.projectInformatin?.status !== undefined) ||
            (project?.projectInformatin?.status === 'On Setup' &&
              project?.projectInformatin?.service !== undefined &&
              project?.projectInformatin?.status !== undefined)) && (
            <Link to={`/designer/project`} className="fs-6 fw-bold">
              <img
                src={'/dist/img/custom/caret-left-light.svg'}
                className="icon-4 me-2"
                alt="img"
              />
              Project Detail
              {project?.projectInformatin?.roomTypes
                ? ' - ' + project?.projectInformatin?.roomTypes
                : '-'}
            </Link>
          )}
          {((project?.status === 'New Project' &&
            project?.status !== undefined) ||
            (project?.status === 'Cancelled' &&
              project?.status !== undefined) ||
            (project?.status === 'On Setup' &&
              project?.service !== undefined &&
              project?.status !== undefined)) && (
            <Link to={`/designer/project`} className="fs-6 fw-bold">
              <img
                src={'/dist/img/custom/caret-left-light.svg'}
                className="icon-4 me-2"
                alt="img"
              />
              Project Detail
              {project?.projectInformatin?.roomTypes
                ? ' - ' + project?.projectInformatin?.roomTypes
                : '-'}
            </Link>
          )}

          {project?.projectInformatin?.status !== 'New Project' &&
            project?.projectInformatin?.status !== undefined &&
            project?.projectInformatin?.status !== 'Cancelled' &&
            project?.projectInformatin?.status !== undefined &&
            !(project?.projectInformatin?.status === 'On Setup') &&
            project?.projectInformatin?.service !== undefined &&
            project?.projectInformatin?.status !== undefined && (
              <Link
                to={`/designer/project/view/${orderNo}`}
                className="fs-6 fw-bold"
              >
                <img
                  src={'/dist/img/custom/caret-left-light.svg'}
                  className="icon-4 me-2"
                  alt="img"
                />
                Project Detail
                {project?.projectInformatin?.roomTypes
                  ? ' - ' + project?.projectInformatin?.roomTypes
                  : '-'}
              </Link>
            )}
          {project?.status !== 'New Project' &&
            project?.status !== undefined &&
            project?.status !== 'Cancelled' &&
            project?.status !== undefined &&
            !(project?.status === 'On Setup') &&
            project?.service !== undefined &&
            project?.status !== undefined && (
              <Link
                to={`/designer/project/view/${orderNo}`}
                className="fs-6 fw-bold"
              >
                <img
                  src={'/dist/img/custom/caret-left-light.svg'}
                  className="icon-4 me-2"
                  alt="img"
                />
                Project Detail
                {project?.projectInformatin?.roomTypes
                  ? ' - ' + project?.projectInformatin?.roomTypes
                  : '-'}
              </Link>
            )}
        </div>
      </div>

      {/* Loading */}
      {isLoadingData === true && <LoadingComponent />}

      {/* Project Detail Component */}
      {project !== null && isLoadingData === false && (
        <ProjectDetailComponent
          project={project}
          setIsLoadingData={props.setLoading}
          tsx={tsx}
        />
      )}

      {/* Button Accept / reject, if status new project */}
      {(project?.projectInformatin?.status === 'New Project' ||
        project?.status === 'New Project') &&
        isLoadingData === false && (
          <div className="text-right">
            <button
              type="button"
              className="btn btn-danger fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0 mt-3"
              onClick={handleRejectOrder}
            >
              Reject
            </button>
            <button
              type="button"
              className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0 mt-3"
              onClick={handleAcceptOrder}
            >
              Accept
            </button>
          </div>
        )}
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(ProjectDetail);
